import React from "react";
import { Link } from "react-scroll";
import {
  Container,
  Box,
  Grid,
  Typography,
  Stack,
  Button,
  useMediaQuery,
} from "@mui/material";
import CampaignIcon from "@mui/icons-material/Campaign";
import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";

// material icons
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";

// components
import Video from "./Video";
import { MeetGreetWidget } from "../MeetGreet";
import HeroBackgroundImage from "../../assets/hero-bg.jpg";

const Hero = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexGrow: 1,
        backgroundColor: grey[200],
        py: { xs: 2, sm: 12 },
        backgroundImage: `url(${HeroBackgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPistion: "right center",
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MeetGreetWidget />
          </Grid>

          <Grid item xs={12} md={6}>
            <Box
              sx={{
                p: 2,
                borderRadius: "4px",
                background: "rgba(204, 204, 204, .35)",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 900,
                  textAlign: { xs: "center", md: "left" },
                }}
                gutterBottom
              >
                Greeted with a Friendly Face
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  textAlign: { xs: "center", md: "left" },
                  mb: { xs: 2 },
                }}
              >
                If you’re arriving at Heathrow we can be there to ensure your
                onward journey runs smoothly. We have three levels of service
                available; Call on Arrival, Meet and Greet and VIP Meet and
                Greet. With this service your booking will be noted and a car
                will be ready at the time requested however due to strict
                regulations at the airports regarding vehicle waiting times the
                car will be on standby near to your terminal.
              </Typography>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CampaignIcon color="warning" />
                <Typography variant="h5" gutterBottom>
                  London Gatwick Meet and Greet Service
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent={isSmallScreen ? "center" : "start"}
                alignItems={isSmallScreen ? "center" : "start"}
                spacing={2.5}
                sx={{ mt: 3 }}
              >
                <Link
                  activeClass="active"
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <Button variant="contained" color="primary">
                    Get In Touch
                  </Button>
                </Link>
                <Video
                  startIcon={<PlayCircleFilledWhiteIcon />}
                  btnText="Watch Intro"
                />
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Hero;
